import ErrorData from "../../data/error/error";
import { isTT } from "../../utils/common";
import {checkRequest} from "../check-request/check-request2";
import CheckResponseStatus from "../check-response-status/check-response-status";
import CheckResponse from "../check-response/check-response";
import Popup from "../popup/popup";


export default class RequestController {

  constructor({api, parameter, checkRequiredParameter = {}, requiredResponseParameters = [], getRequest, parseResponse, isShowError = true, isSendOffline = false, onResolveHandler = () => {}, onRejectHandler = () => {}, onAbortHandler = () => {}, onRequestErrorHandler = () => {}}) {
    this._api = api;
    this._parameter = parameter;
    this._checkRequiredParameter = checkRequiredParameter;
    this._requiredResponseParameters = requiredResponseParameters;
    this._getRequest = getRequest;
    this._parseResponse = parseResponse;
    this._isShowError = isShowError;
    this._isSendOffline = isSendOffline;
    this._onResolveHandler = onResolveHandler;
    this._onRejectHandler = onRejectHandler;
    this._onAbortHandler = onAbortHandler;
    this._onRequestErrorHandler = onRequestErrorHandler;

    this._errorData = new ErrorData({api: this._api});

    this._request = ``;
    this._response = ``;

    this.fetched = [];
  }

  async send() {
    return Promise.resolve(this._getRequest(this._parameter))
    .then((request) => this._setRequest(request))
    .then(() => this._checkRequest())
    .then(() => this._postData())
    .then((response) => this._checkStatus(response))
    .then((responseJSON) => this._setResponse(responseJSON))
    .then(() => this._checkResponse())
    .catch((error) => this._showError(error));
  }

  // sendError() {
  //   return Promise.resolve(this._getRequest(this._parameter))
  //   .then((request) => this._setRequest(request))
  //   .then(() => this._checkRequest())
  //   .then(() => this._postData())
  //   .then((response) => this._checkStatus(response))
  //   .then((responseJSON) => this._setResponse(responseJSON))
  //   .then(() => this._checkResponse())
  //   .catch((error) => this._showError(error));
  // }

  sendFormData(formData, abortSignal) {
    this._setRequest(formData);

    this._postFormData(abortSignal)
    .then((response) => this._checkStatus(response))
    .then((responseJSON) => this._setResponse(responseJSON))
    .then(() => this._checkResponse())
    .catch((error) => this._showError(error));
  }

  loadFileChunk(blob, fileId, start, abortSignal) {
    this._postFileChunk(blob, fileId, start, abortSignal)
    .then((response) => this._checkStatus(response))
    .then((responseJSON) => this._setResponse(responseJSON))
    .then(() => this._checkResponse())
    .catch((error) => this._showError(error));
  }

  loadFileDone(fileName, fileId) {
    this._postFileDone(fileName, fileId)
    .then((response) => this._checkStatus(response))
    .then((responseJSON) => this._setResponse(responseJSON))
    .then(() => this._checkResponse())
    .catch((error) => this._showError(error));
  }

  _checkStatus(response) {
    if (this._parameter && this._parameter.nameError && this._parameter.nameError === `ResponseStatusError`) {
      return response.json();
    }

    CheckResponseStatus.check(response.status);

    return response.json();
  }

  _postData() {
    if (!this._isSendOffline && !this._isOnLine()) {
      return new Promise((resolve) => {
        window.addEventListener(`online`, () => {
          return resolve(this._api.postData(this._request));
        });
      });
    }

    return this._api.postData(this._request);
  }

  _postFormData(abortSignal) {
    if (!this._isSendOffline && !this._isOnLine()) {
      return new Promise((resolve) => {
        window.addEventListener(`online`, () => {
          return resolve(this._api.postFormData(this._request, abortSignal));
        });
      });
    }

    return this._api.postFormData(this._request, abortSignal);
  }

  _postFileChunk(blob, fileId, start, abortSignal) {
    if (!this._isSendOffline && !this._isOnLine()) {
      return new Promise((resolve) => {
        window.addEventListener(`online`, () => {
          return resolve(this._api.postFileChunk(blob, fileId, start, abortSignal));
        });
      });
    }

    return this._api.postFileChunk(blob, fileId, start, abortSignal);
  }

  _postFileDone(fileName, fileId) {
    if (!this._isSendOffline && !this._isOnLine()) {
      return new Promise((resolve) => {
        window.addEventListener(`online`, () => {
          return resolve(this._api.postFileDone(fileName, fileId));
        });
      });
    }

    return this._api.postFileDone(fileName, fileId);
  }

  _checkRequest() {
    checkRequest({
      checkedAvailableParameters: this._parameter,
      checkedRequiredParameter: this._checkRequiredParameter,
    });
  }

  _checkResponse() {
    CheckResponse.check({
      response: this._response,
      checkedRequiredParameter: this._requiredResponseParameters,
    });

    const parseResponse = this._onParseResponse(this._response);

    this._onResolveHandler(parseResponse);
  }

  _onParseResponse(response) {
    if (!this._parseResponse) {
      return response;
    }

    return this._parseResponse(response);
  }

  _setRequest(data) {
    this._request = data;
    // this.fetched.push(data);
    // debugger;
  }

  _setResponse(data) {
    this._response = data;
  }

  _showError(error) {
    // eslint-disable-next-line no-console
    console.log(error);

    if (error.name === `ScriptError`) {
      this._errorData.request({
        nameError: error.name,
        message: error.message,
        stack: error.stack,
        request: this._request,
        response: this._response
      })
      .then((errorId) => {
        if (this._isShowError) {
          error.show(errorId);
        }
      })
      .then(() => this._onRejectHandler(error));

      return;
    }

    if (error.name === `FetchError`) {
      // RequestController._fetchedRequests.push(this._request);
      // console.log(RequestController._fetchedRequests);

      if (this._isShowError) {
        error.show();
      }

      // if (isTT()) {
      // debugger;
      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        setTimeout(() => {
          // debugger;
          this._postData()
          .then((response) => this._checkStatus(response))
          .then((responseJSON) => this._setResponse(responseJSON))
          .then(() => this._checkResponse())
          .catch((error) => this._showError(error));
        }, 3000);
      });
      // }

      // if (!isTT()) {
      //   this._errorData.request({
      //     nameError: error.name,
      //     message: error.message,
      //     stack: error.stack,
      //     request: this._request,
      //     response: this._response,
      //     errorType: error.type,
      //   })
      //   .then(() => this._onRejectHandler(error));

      //   if (this._isSendOffline) {
      //     this._onRejectHandler(error);
      //   }
      // }

      return;
    }

    if (error.name === `ServerError`) {
      this._errorData.request({
        nameError: error.name,
        message: error.message,
        stack: error.stack,
        request: this._request,
        response: this._response
      })
      .then((errorId) => {
        if (this._isShowError) {
          error.show(errorId);
        }
      })
      .then(() => this._onRejectHandler(error));

      return;
    }

    if (error.name === `ResponseStatusError`) {
      this._errorData.request({
        nameError: error.name,
        message: error.message,
        stack: error.stack,
        request: this._request,
        response: this._response
      })
      .then((errorId) => {
        if (this._isShowError) {
          error.show(errorId, error.message);
        }
      })
      .then(() => this._onRejectHandler(error));

      return;
    }

    if (error.name === `NoAccessError`) {
      if (this._isShowError) {
        error.show();
      }

      this._onRejectHandler(error);
      return;
    }

    if (error.name === `BlockedError`) {
      if (this._isShowError) {
        error.show();
      }

      return;
    }

    if (error.name === `RequestError`) {
      // if (this._isShowError) {
      this._onRequestErrorHandler(error);
      this._onRejectHandler(error);
      // }

      return;
    }

    if (error.name === `WarningError`) {
      this._onRequestErrorHandler(error);
      this._onRejectHandler(error);

      return;
    }

    // если неожиданная ошибка - то отправить и показать
    // SetDataError.request(this._api, error.name, error.message, error.stack, this._request, this._response)
    this._errorData.request({
      nameError: error.name,
      message: error.message,
      stack: error.stack,
      request: this._request,
      response: this._response
    })
    .then((errorId) => {
      if (this._isShowError) {
        Popup.showError({text: `Произошла ошибка. Код ошибки: ${errorId}`});
      }
    })
    .then(() => this._onRejectHandler(error));
  }

  _isOnLine() {
    return window.navigator.onLine;
  }
}
