import {getChangedQuotText, getChangedQuotText2} from '../../../utils/common.js';
import AbstractSmartComponent from '../../abstract-smart-component.js';


const createDropDownTemplate = (dropDownItems, buttonTitle) => {
  const items = dropDownItems.map((dropDownItem) => {
    const {text, id, isDisabled = false, isChecked = false, isMarked = false, comment = ``, svg = ``, color = ``} = dropDownItem;

    return (
      `<li class="dropDownList__optionItem" data-id="${getChangedQuotText(id)}" data-disabled="${isDisabled}" data-check="${isChecked}" data-mark=${isMarked}>
        ${svg ? `<svg class="dropDownList__icon" ${color ? `style="fill: ${color};"` : ``} aria-hidden="true" width="32" height="32">
          <use class="" href="${svg}"></use>
        </svg>` : ``}  
        <span>${getChangedQuotText(text)}</span> ${comment}
      </li>`
    );
  })
  .join(`\n`);

  const buttonTitleMarkup = (buttonTitle !== ``) ?
    `<li class="">
      <button class="modal__button" type="button">${buttonTitle}</button>
    </li>` : ``;

  return (
    `<ul class="dropDownList__select">
      ${items}
      ${buttonTitleMarkup}
    </ul>`
  );
};

export default class DropDownComponent extends AbstractSmartComponent {
  constructor(dropDownItems, buttonTitle = ``) {
    super();

    this._dropDownItems = dropDownItems;
    this._buttonTitle = buttonTitle;
    this._optionClickHandler = null;
    this._buttonClickHandler = null;
  }

  recoveryListeners() {
    this.setOptionClickHandler(this._optionClickHandler);
    this.setButtonClickHandler(this._buttonClickHandler);
  }

  getTemplate() {
    return createDropDownTemplate(this._dropDownItems, this._buttonTitle);
  }

  set dropDownItems(data) {
    this._dropDownItems = data;
    super.rerender();
  }

  _checkOption(option) {
    option.dataset.check = `true`;

    const options = this.getElement().querySelectorAll(`.dropDownList__optionItem`);
    Array.from(options)
    .filter((item) => item.dataset.check === `true`)
    .filter((item) => item !== option)
    .forEach((item) => {
      item.dataset.check = `false`;
    });
  }

  setOptionClickHandler(handler) {
    this._optionClickHandler = handler;

    const options = this.getElement().querySelectorAll(`.dropDownList__optionItem`);

    options.forEach((option) => {
      option.addEventListener(`click`, () => {
        this._checkOption(option);
        handler(getChangedQuotText2(option.dataset.id), option.dataset.disabled === `true`);
      });
    });
  }

  setButtonClickHandler(handler) {
    if (!handler) {
      return;
    }
    this._buttonClickHandler = handler;

    this.getElement().querySelector(`.modal__button`)
      .addEventListener(`click`, handler);
  }
}
